<router>
{
    name: 'Customers',
}
</router>
<template lang="pug">
v-container(fluid)
    v-row
        v-col(cols='8')
            DevopsHelp

</template>
<script>

import DevopsHelp from '@/components/devops/help';
export default {
    meta: {
        role: 'devolpshelp'
    },
    components: {
        DevopsHelp
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
    }
};
</script>
